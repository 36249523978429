import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import gallery from "../../assets/gallery.svg";
// import ImageLoader from "../ImageLoader";

const cookies = new Cookies();

const ImgGeneration = ({ setStep, prompt, setData }) => {
  const imgRef = useRef();
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const effectRan = useRef(false);
  let count = 0;
  useEffect(() => {
    if (effectRan.current) return;

    effectRan.current = true;
    cookies.remove("image");
    const imgs = cookies.get("image");
    if (imgs) {
      setImages(imgs);
      setLoading(false);
    } else {
      fetchImages();
    }
  }, []);

  var fetchImages = () => {
    axios
      .post("https://hook.eu2.make.com/1wb8wdiuj5yawrbwhf7f054ex57h5tvm", {
        type: "image",
        prompt,
      })
      .then((res) => {
        setLoading(false);
        setImages((prev) => [...prev, ...res.data]);
        count += 1;
        if (count < 4) {
          fetchImages();
        } else {
          // set cookies expiry after 10 minutes
          const jsonString = JSON.stringify(res.data);
          cookies.set("image", jsonString, {
            path: "/",
            maxAge: 2 * 60 * 60,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleNextStep = () => {
    if (selected) {
      setData((prev) => ({ ...prev, image: selected }));
      setStep(2);
    }
  };
  const handleImage = (e) => {
    const image = e.target.files[0];
    // display image
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      setImage(event.target.result);
    };
  };
  return (
    <div
      className="bg-[#24212F] rounded-[10px] p-4"
      style={{ border: "1px solid #302C3F" }}
    >
      {/* <div className="flex items-center justify-between">
        <img src={unsplash} alt="" />
        <img className="cursor-pointer" src={cross} alt="" />
      </div> */}
      <div className="flex justify-between items-center">
        <input
          type="file"
          className="hidden"
          ref={imgRef}
          onChange={handleImage}
          accept="image/*"
        />
        {/* <div className="flex items-center gap-2 mt-5">
          <div className="cursor-pointer w-12 h-12  rounded-full bg-[#302C3F] flex justify-center items-center">
            <img src={search} alt="" />
          </div>
          <span className="cursor-pointer ">Search</span>{" "}
        </div> */}
        <h2>Seleziona un'immagine qualsiasi</h2>
        <div className="flex gap-4   items-center">
          {/* <button
            // onClick={handlePrevStep}
            className="  bg-[#D9D9D9] text-[#5E5678] font-medium rounded-full py-2 px-6"
          >
            Cancel
          </button> */}
          <button
            onClick={() => imgRef.current.click()}
            className="gradient_border  bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2 flex gap-2 items-center cursor-pointer"
          >
            <div className="w-5">
              <img src={gallery} alt="" className="w-full" />
            </div>{" "}
            caricamento
          </button>
          <button
            onClick={handleNextStep}
            disabled={!selected}
            className="gradient_border  bg-[#13111A] text-[#FFFFFF] font-medium rounded-full  px-6 py-2 cursor-pointer"
          >
            Conferma
          </button>
        </div>
      </div>
      {/* <div className="flex justify-between items-center mt-8">
        <div className="flex gap-6 items-center">
          <select
            className=" font-medium text-white  bg-[#24212F] focus:outline-none"
            name=""
            id=""
          >
            <option value="Relevance">Relevance</option>
          </select>{" "}
          <select
            className=" font-medium text-white  bg-[#24212F] focus:outline-none"
            name=""
            id=""
          >
            <option value="All orientations">All orientations</option>
          </select>
          <select
            className=" font-medium text-white  bg-[#24212F] focus:outline-none"
            name=""
            id=""
          >
            <option value="Relevance">Relevance</option>
          </select>{" "}
          <select
            className=" font-medium text-white  bg-[#24212F] focus:outline-none"
            name=""
            id=""
          >
            <option value="All licenses">All licenses</option>
          </select>{" "}
        </div>
        <div>10,000 results</div>
      </div> */}
      {/* {loading ? (
        <h2 className="text-lg text-center mt-3">Generazione di immagini...</h2>
      ) : ( */}
      <div className="flex justify-start items-center mt-4 gap-3 flex-wrap">
        {image && (
          <img
            onClick={() => setSelected(image)}
            src={image}
            className={`p-1 rounded-[20px] w-[200px] h-[200px] cursor-pointer object-cover ${
              selected === image ? "gradient_border" : ""
            }`}
          />
        )}
        {images.map((img) => (
          <img
            onClick={() => setSelected(img.url)}
            src={img.url}
            alt=""
            className={`p-1 rounded-[20px] w-[200px] h-[200px] cursor-pointer object-cover ${
              selected === img.url ? "gradient_border" : ""
            }`}
          />
        ))}
        {Array(4 - images.length)
          .fill("")
          .map((_, index) => (
            <lord-icon
              src="https://cdn.lordicon.com/wleoetna.json"
              trigger="loop"
              colors="primary:#6f86ff,secondary:#fc7588"
              style={{ width: "200px", height: "200px" }}
            ></lord-icon>
          ))}
      </div>
      {/* )} */}
    </div>
  );
};

export default ImgGeneration;
